.navbar__padding {
    height: 120px;
}

.navbar__bg-container.visible {
    height: 110px;
    overflow: visible;
}

.navbar__bg-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 0px;
    z-index: 10;
    overflow: hidden;
    transition: height ease-in 300ms;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/nav-background.jpg');
}

.navbar__bg-container > .navbar__content {
    height: 110px;
}

.navbar-bg-color {
    background-color: #0e1730;
}

.navbar__content > a{
    text-decoration: none;
    color: white;
}

.mobile-nav__container {
    transition: transform ease-in 300ms;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 300px;
    overflow: auto;
    
    transform: translate(-100%, 0);
}

.mobile-nav__bottom {
    transition: transform ease-in 300ms;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    transform: translate(0, 100%);
}

.mobile-nav__container.visible,
.mobile-nav__bottom.visible {
    transform: translate(0, 0);
}

.mobile-nav__container > * {
    margin-bottom: 0.5rem;
}

.navbar__logo--width {
    max-width: 15rem;
    width: 100%;
}

.nav__contact-us > span:nth-child(2) {
    display: none;
}

.nav__contact-us:hover > span:nth-child(1) {
    display: none;
}

.nav__contact-us:hover > span:nth-child(2) {
    display: block;
}